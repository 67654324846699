import {
  FundId,
  FundShareClassId,
  ProductData,
  ProductType,
  ShareClassCode,
} from '@products/types';
import { ExpressNumber, GlobalId } from './nominals.type';
import { LiteratureDocument } from '@literature/types';
import { Nominal } from 'simplytyped';
import { FavoritesStatus } from '@types';
import { USServicingSalesTeamListDto } from '../ft-components/interactive-content/us-servicing/us-servicing-banner/types/us-servicing-banner.types';

export type PersonalisationProductRelationship =
  | 'favorite'
  | 'subscribed'
  | 'held';
export type PersonalisationToken = Nominal<string, 'PersonalisationToken'>;

export interface PersonalisationPersonalDataDocuments {
  asOfDate: string;
  documentType: string;
  documentTitle: string;
  documentURL: string;
  productId: string;
  productName: string;
  updatedDate?: string;
  fileUploadDate: string;
  createdDate?: string;
  // date format coming as '2023-06-22 09:36:45.912'
  primaryShareClassCode?: ShareClassCode;
  translatedDocumentType?: string;
  isChecked?: boolean;
  MSDContactGUIDId?: string;
  documentLanguage?: string;
  friendlyDocType?: string;
  productSubscriptionId?: string;
  strategicInvestmentManager?: string;
}

export interface PersonalisationPersonalDataProductDto {
  oneTis: FundShareClassId;
  sortOrder?: number;
}

export interface PersonalisationFundDoc extends LiteratureDocument {
  fileLink?: string;
}

export interface PersonalisationPersonalDataDto {
  name?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneExtension?: string;
  phoneNumber?: string;
  companyName?: string;
  dealerNumber?: string;
  branchNumber?: string;
  repNumber?: string;
  ftcomRegistered?: 'Y' | 'N';
  advisorTeamId?: GlobalId;
  advisorTeamName?: string;
  clientType?: string;
  riaTerrId?: string;
  isLoggedIn?: boolean;
  dcLeadFlag?: string;
  firmGlobalId?: GlobalId;
  parentFirmGlobalId?: GlobalId;
  localSalesTeam?: string;
  identifiers: {
    globalId: GlobalId;
    expressNumber: ExpressNumber;
  };
  favoriteProducts?: PersonalisationPersonalDataProductDto[];
  heldProducts?: PersonalisationPersonalDataProductDto[];
  //  Type of data below set to string base on information from mule team.
  salesTeamCoverage: string;
  subscribedDocuments: PersonalisationPersonalDataDocuments[];
  subscribedProducts?: PersonalisationPersonalDataProductDto[];
  teamInvestedFundFlag?: 'Y' | 'N' | null;
  focusList: PersonalisationFavoriteData[];
  primarySalesPerson: USServicingSalesTeamListDto;
}

export interface PersonalisationFirmDataDto {
  clientType: string;
  firmCrd: string;
  firmGlobalId: GlobalId;
  firmPhone: string;
  firmRegistrationType: string;
  firmWebsites: string;
  heldProducts: PersonalisationPersonalDataProductDto[];
  localTeam: string;
  name: string;
  parentFirmGlobalId: GlobalId;
  pwdSegment: string;
  retailFirmSegment: string;
  salesOffice: string;
  status: string;
  subDivId: string;
  team: string;
  platformProducts?: FundShareClassId[];
  firmPlatform?: FirmPlatformDto[]; // TODO : will need for the productavailability guide
}

export interface FirmPlatformDto {
  platformId: string;
  platformName: string;
  platformType: string;
  platformAvailable: string;
  platformDescription: string;
  platformProducts: PlatformProductDto[];
}

export interface PlatformProductDto {
  assetClass: string;
  shareClassDisplayName?: string;
  productVehicle: string;
  productName: string;
  shareClassText?: string;
  platformTisShareclass: FundShareClassId;
  cioGroup: string;
  tickerSymbols?: string;
  shareClassTicker?: string;
}

export enum PlatformQueryParam {
  ALL = 'all',
  PRODUCTS = 'products',
}

export interface PersonalisationPersonalDataErrorDto {
  error: {
    message: string;
  };
}

export interface PersonalisationPersonalDataProduct {
  fundId: FundId;
  shareClassCode: ShareClassCode;
  relationships: PersonalisationProductRelationship[];
  sortOrder?: number;
}

export interface PersonalisationFundDocs
  extends PersonalisationPersonalDataProduct {
  product?: ProductData;
  docs?: PersonalisationFundDoc[];
}

export interface PersonalisationFundDocsRequest {
  data: PersonalisationFundDocs[];
  hasLoaded: boolean;
}

export interface PersonalisationPersonalData {
  name?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneExtension?: string;
  phoneNumber?: string;
  companyName?: string;
  advisorTeamId?: GlobalId;
  advisorTeamName?: string;
  clientType?: string;
  riaTerrId?: string;
  isLoggedIn?: boolean;
  isDcLead?: boolean;
  firmGlobalId?: GlobalId;
  parentFirmGlobalId?: GlobalId;
  localSalesTeam?: string;
  dbrInfo?: {
    dealerNumber?: string;
    branchNumber?: string;
    repNumber?: string;
  };
  ftcomRegistered?: boolean;
  identifiers: {
    globalId: GlobalId;
    expressNumber?: ExpressNumber;
  };
  subscribedDocuments?: PersonalisationPersonalDataDocuments[];
  subscribedProducts?: PersonalisationPersonalDataProduct[];
  teamInvestedFundFlag?: boolean;
  hasPersonalHeldProducts?: boolean;
  focusList?: PersonalisationFavoriteData[];
  primarySalesPerson?: USServicingSalesTeamListDto;
}

export interface PersonalisationFavoriteData {
  productType: ProductType;
  status: FavoritesStatus;
  tisShareclassCode: FundShareClassId;
  updatedon: string;
  addedby: string;
  fundName: string;
  fundUrl: string;
}

export interface PersonalisationPersonalDataWithProducts
  extends PersonalisationPersonalData {
  products?: PersonalisationPersonalDataProduct[];
  heldProducts?: PersonalisationPersonalDataProduct[];
  firmProducts?: PersonalisationPersonalDataProduct[];
}

export interface PersonalisationRegisterAPITokenData {
  userGroup: string;
  userId?: string;
  businessKey?: string;
}

export enum PersonalisationLocalSalesTeam {
  // TODO: Add other types if required
  RIA = 'RIA & Bank Trust',
}

// Type base on MuleSoft API documentation https://ft-personalisation-api-dev.us-w2.cloudhub.io/console/#docs/method/#846
export interface BrightcoveVideoDto {
  id: string;
  account_id: string;
  ad_keys: any;
  clip_source_video_id: any;
  complete: boolean;
  created_at: string;
  created_by: {
    type: string;
    id: string;
    email: string;
  };
  cue_points: any[];
  custom_fields: any;
  delivery_type: string;
  description: any;
  digital_master_id: any;
  duration: number;
  economics: string;
  folder_id: any;
  geo: any;
  has_digital_master: boolean;
  images: {
    poster: {
      src: string;
      sources: [
        {
          src: string;
          height: number;
          width: number;
        }
      ];
    };
    thumbnail: {
      src: string;
      sources: [
        {
          src: string;
          height: number;
          width: number;
        }
      ];
    };
  };
  link: any;
  long_description: any;
  name: string;
  original_filename: string;
  projection: any;
  published_at: string;
  reference_id: any;
  schedule: any;
  sharing: any;
  state: string;
  tags: any[];
  text_tracks: any[];
  transcripts: any[];
  updated_at: string;
  updated_by: {
    type: string;
  };
  playback_rights_id: string;
  ingestion_profile_id: string;
}
