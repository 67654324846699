import { Injectable } from '@angular/core';
import {
  LOGOUT_TITLE,
  MANUAL_LOGOUT,
  AUTO_LOGOUT,
  PWD_LOGGED_LOGOUT,
  SECURITY_LOGGED_LOGOUT,
  MOS_PWD_LOGGED_LOGOUT,
  MOS_SECURITY_LOGGED_LOGOUT,
  SESSION_EXPIRED,
  MANUAL_LOGOUT_NO_ACCOUNTS,
  AUTO_LOGOUT_LABEL,
  MANUAL_LOGOUT_LABEL,
  MOS_PWD_LOGGED_LOGOUT_LABEL,
  MOS_SECURITY_LOGGED_LOGOUT_LABEL,
  PWD_LOGGED_LOGOUT_LABEL,
  SECURITY_LOGGED_LOGOUT_LABEL,
  TRUE,
  FALSE,
  REAUTHENTICATION,
  API_ERROR,
  API_ERROR_TITLE,
  UPGRADE_TITLE,
} from '@utils/app.constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { Logger } from '@utils/logger';
import {
  AlertObj,
  NotificationDetails,
  NotificationTypes,
  SwitchSegment,
} from '@accounts/accounts-alerts/accounts-alerts.interface';
const logger = Logger.getLogger('AlertService');
@Injectable({
  providedIn: 'root',
})
export class AlertService {
  public accountsAlertSubject$: BehaviorSubject<NotificationDetails> = new BehaviorSubject<NotificationDetails>(
    null
  );

  public accountsAlertDetails = {} as NotificationDetails;
  public siteAlertDetails: NotificationDetails;
  public switchSegment = {} as SwitchSegment;
  /**
   * Visibility of alert
   */
  public showAccountsAlert = false;
  public showSiteAlert = false;
  /**
   * Don't show siteAlert once its dismissed by the user on each page
   */
  public isSiteAlertDismissed = false;
  constructor() {}
  /**
   * Set Alert based on the logout type
   */
  public setAccountsAlert(alertObj?: AlertObj): void {
    this.clearAccountsAlert();
    logger.info('alertObj: ' + alertObj);
    if (
      alertObj &&
      alertObj?.logout &&
      (alertObj?.logoutType === MANUAL_LOGOUT || alertObj?.accounts === TRUE) &&
      alertObj?.role &&
      alertObj?.segmentType
    ) {
      this.switchSegment.id = alertObj?.role;
      this.switchSegment.segmentType = alertObj?.segmentType;
      this.accountsAlertSubject$.next(
        this.setAccountsAlertDetails(
          LOGOUT_TITLE,
          true,
          '',
          NotificationTypes.CONFIRM,
          MANUAL_LOGOUT_NO_ACCOUNTS,
          this.switchSegment
        )
      );
    } else if (
      alertObj &&
      alertObj?.logout &&
      alertObj?.logoutType === MANUAL_LOGOUT &&
      alertObj?.accounts === TRUE
    ) {
      this.accountsAlertSubject$.next(
        this.setAccountsAlertDetails(
          LOGOUT_TITLE,
          true,
          '',
          NotificationTypes.CONFIRM,
          MANUAL_LOGOUT_LABEL,
          this.switchSegment
        )
      );
    } else if (
      alertObj &&
      alertObj?.logout &&
      alertObj?.logoutType === MANUAL_LOGOUT
    ) {
      this.accountsAlertSubject$.next(
        this.setAccountsAlertDetails(
          LOGOUT_TITLE,
          true,
          '',
          NotificationTypes.CONFIRM,
          MANUAL_LOGOUT_NO_ACCOUNTS,
          this.switchSegment
        )
      );
    } else if (
      alertObj &&
      alertObj?.logout &&
      alertObj?.logoutType === AUTO_LOGOUT
    ) {
      this.accountsAlertSubject$.next(
        this.setAccountsAlertDetails(
          SESSION_EXPIRED,
          true,
          '',
          NotificationTypes.CONFIRM,
          AUTO_LOGOUT_LABEL,
          this.switchSegment
        )
      );
    } else if (
      alertObj &&
      alertObj?.logout &&
      alertObj?.logoutType === PWD_LOGGED_LOGOUT
    ) {
      this.accountsAlertSubject$.next(
        this.setAccountsAlertDetails(
          REAUTHENTICATION,
          true,
          '',
          NotificationTypes.CONFIRM,
          PWD_LOGGED_LOGOUT_LABEL,
          this.switchSegment
        )
      );
    } else if (
      alertObj &&
      alertObj?.logout &&
      alertObj?.logoutType === SECURITY_LOGGED_LOGOUT
    ) {
      this.accountsAlertSubject$.next(
        this.setAccountsAlertDetails(
          REAUTHENTICATION,
          true,
          '',
          NotificationTypes.CONFIRM,
          SECURITY_LOGGED_LOGOUT_LABEL,
          this.switchSegment
        )
      );
    } else if (
      alertObj &&
      alertObj?.logout &&
      alertObj?.logoutType === MOS_PWD_LOGGED_LOGOUT
    ) {
      this.accountsAlertSubject$.next(
        this.setAccountsAlertDetails(
          REAUTHENTICATION,
          true,
          '',
          NotificationTypes.CONFIRM,
          MOS_PWD_LOGGED_LOGOUT_LABEL,
          this.switchSegment
        )
      );
    } else if (
      alertObj &&
      alertObj?.logout &&
      alertObj?.logoutType === MOS_SECURITY_LOGGED_LOGOUT
    ) {
      this.accountsAlertSubject$.next(
        this.setAccountsAlertDetails(
          REAUTHENTICATION,
          true,
          '',
          NotificationTypes.CONFIRM,
          MOS_SECURITY_LOGGED_LOGOUT_LABEL,
          this.switchSegment
        )
      );
    } else if (alertObj && alertObj?.apiType && alertObj?.type === API_ERROR) {
      this.accountsAlertSubject$.next(
        this.setAccountsAlertDetails(
          API_ERROR_TITLE,
          true,
          '',
          NotificationTypes.ERROR,
          API_ERROR,
          this.switchSegment
        )
      );
    } else if (alertObj && alertObj?.notify && alertObj?.type) {
      this.accountsAlertSubject$.next(
        this.setAccountsAlertDetails(
          UPGRADE_TITLE,
          true,
          '',
          NotificationTypes.ALERT,
          alertObj?.type,
          this.switchSegment
        )
      );
    } else {
      this.accountsAlertSubject$.next(null);
    }
  }

  /**
   * Set Alert details object from the set alert methods
   */
  setAccountsAlertDetails(
    title: string,
    active: boolean,
    subTitle: string,
    notificationType: NotificationTypes,
    notificationLabel: string,
    segment: SwitchSegment
  ): NotificationDetails {
    this.accountsAlertDetails.title = title;
    this.accountsAlertDetails.active = active;
    this.accountsAlertDetails.subTitle = subTitle;
    this.accountsAlertDetails.notificationType = notificationType;
    this.accountsAlertDetails.notificationLabel = notificationLabel;
    this.accountsAlertDetails.segment = segment;
    logger.info(
      'accountsAlertDetails: ' + this.accountsAlertDetails?.notificationLabel
    );
    return this.accountsAlertDetails;
  }
  /**
   * Clear Alert details
   */
  clearAccountsAlert(): void {
    this.accountsAlertSubject$.next(null);
  }

  /**
   * Get Alert
   */
  getAccountsAlert$(): Observable<NotificationDetails> {
    return this.accountsAlertSubject$.asObservable();
  }
}
